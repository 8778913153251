<template>
  <div class="auth-wrapper auth-v2">
    <ParticleAnimation />

    <div class="auth-left" ref="authParentBox">
      <div class="auth-box" ref="authChildBox">
        <b-link class="">
          <img
            src="@/assets/images/svg/logo.svg"
            style="width: 270px; padding-top: 8px"
          />
        </b-link>
        <img src="@/assets/images/svg/teethlogin.png" class="teeth" alt="" />
        <img src="@/assets/images/svg/vector.png" class="vectors" alt="" />
        <p>
          {{ $t("Efficiency & Quality") }} -<br />{{
            $t("let AI revolutionize your work")
          }}!
        </p>
      </div>
    </div>
    <div class="auth-right register-page">
      <div class="language-dropdown">
        <ul>
          <Language />
        </ul>
      </div>
      <div class="auth-detail">
        <h3>{{ $t("Referral") }} : {{ companyName }}</h3>
        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form class="auth-login-form mt-2" @submit.prevent>
            <div class="row">
              <div class="col-md-6 mb-1">
                <validation-provider
                  #default="{ errors }"
                  name="Company Name"
                  rules="required"
                >
                  <TextInput
                    v-model="form.companyName"
                    type="text"
                    :label="$t('Company Name')"
                    :tooltipMessage="$t('Company Name')"
                    :required="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
              <div class="col-md-6 mb-1">
                <validation-provider
                  #default="{ errors }"
                  name="Address Line 1"
                  rules="required"
                >
                  <TextInput
                    v-model="form.addressLine1"
                    type="text"
                    :label="$t('Address Line 1')"
                    :required="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
              <div class="col-md-6 mb-1">
                <TextInput
                  v-model="form.addressLine2"
                  type="text"
                  :label="$t('Address Line 2')"
                />
              </div>
              <div class="col-md-6 mb-1">
                <validation-provider
                  #default="{ errors }"
                  name="City"
                  rules="required"
                >
                  <TextInput
                    v-model="form.city"
                    type="text"
                    :label="$t('City')"
                    :required="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
              <div class="col-md-6 mb-1">
                <validation-provider
                  #default="{ errors }"
                  name="ZIP"
                  rules="required"
                >
                  <TextInput
                    v-model="form.zipCode"
                    type="text"
                    :label="$t('ZIP')"
                    :required="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
              <div class="col-md-6 mb-1">
                <validation-provider
                  #default="{ errors }"
                  name="Country"
                  rules="required"
                >
                  <TextInput
                    v-model="form.country"
                    type="text"
                    :label="$t('Country')"
                    :required="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
              <div class="col-md-6 mb-1">
                <TextInput
                  v-model="form.vatId"
                  type="text"
                  :label="$t('VAT ID')"
                />
              </div>
              <div class="col-md-6 mb-1">
                <TextInput
                  v-model="form.phone"
                  type="text"
                  :label="$t('Phone')"
                />
              </div>
              <div class="col-md-6 mb-2">
                <label class="input-label form-label" for="cars">{{
                  $t("Status")
                }}</label>
                <select class="input-form form-control" v-model="form.status">
                  <option value="active">
                    {{ $t("Active") }}
                  </option>
                  <option value="inactive">
                    {{ $t("Inactive") }}
                  </option>
                </select>
              </div>
              <div class="col-md-6 mb-2"></div>
              <div class="col-md-6 mb-1">
                <!-- email -->
                <b-form-group label-for="login-email">
                  <label class="input-label form-label"
                    ><span style="color: red">*</span>&nbsp;{{ $t("Email") }}
                  </label>
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required"
                  >
                    <input
                      id="email"
                      v-model="form.email"
                      name="title"
                      class="form-control"
                      type="text"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div class="col-md-6 mb-1">
                <!-- forgot password -->
                <b-form-group>
                  <label for="login-password"
                    ><span style="color: red">*</span>&nbsp;
                    {{ $t("Password") }}
                  </label>
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="login-password"
                        v-model="password"
                        :state="errors.length > 0 ? false : null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                        placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
            </div>

            <div class="d-flex align-items-center justify-content-center">
              <!-- submit buttons -->
              <b-button @click="createCustomer" variant="primary" block>
                {{ $t("Register") }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import VuexyLogo from "@core/layouts/components/Logo.vue";
import ParticleAnimation from "@/components/ParticleAnimation.vue";
import Language from "@/components/Language.vue";
import TextInput from "@/components/TextInput.vue";
import PageHeader from "@/components/PageHeader.vue";
import MultiSelect from "vue-multiselect";
import MultiSelectInput from "@/components/MultiSelectInput.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapGetters } from "vuex";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ParticleAnimation,
    Language,
    TextInput,
    PageHeader,
    MultiSelect,
    ValidationProvider,
    MultiSelectInput,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      form: {
        companyName: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        zipCode: "",
        country: "",
        vatId: "",
        phone: "",
        status: "active",
      },
      password: "",
      userEmail: "",
      sideImg: require("@/assets/images/pages/login/login.png"),
      companyName: "",
      email,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  async created() {
    this.$store.commit("showLoader", true);
    await this.$store
      .dispatch("customers/show", this.$route.query.companyId)
      .then((res) => {
        let data = res?.data?.modelData;
        this.companyName = data.companyName;
      });
  },
  mounted() {
    this.setWidth();
    window.addEventListener("resize", this.setWidth);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setWidth);
  },
  methods: {
    setWidth() {
      const parentWidth = this.$refs.authParentBox.offsetWidth;
      const parentheight = this.$refs.authParentBox.offsetHeight;
      this.$refs.authChildBox.style.width = `${parentWidth}px`;
      this.$refs.authChildBox.style.height = `${parentheight}px`;
    },
    async createCustomer() {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success) {
          await this.$store.dispatch("customers/create", {
            ...this.form,
            referralId: this.$route.query.companyId,
          });
          this.form = {
            companyName: "",
            addressLine1: "",
            addressLine2: "",
            city: "",
            zipCode: "",
            country: "",
            vatId: "",
            phone: "",
            status: "active",
          };
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
