var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('ParticleAnimation'),_c('div',{ref:"authParentBox",staticClass:"auth-left"},[_c('div',{ref:"authChildBox",staticClass:"auth-box"},[_c('b-link',{},[_c('img',{staticStyle:{"width":"270px","padding-top":"8px"},attrs:{"src":require("@/assets/images/svg/logo.svg")}})]),_c('img',{staticClass:"teeth",attrs:{"src":require("@/assets/images/svg/teethlogin.png"),"alt":""}}),_c('img',{staticClass:"vectors",attrs:{"src":require("@/assets/images/svg/vector.png"),"alt":""}}),_c('p',[_vm._v(" "+_vm._s(_vm.$t("Efficiency & Quality"))+" -"),_c('br'),_vm._v(_vm._s(_vm.$t("let AI revolutionize your work"))+"! ")])],1)]),_c('div',{staticClass:"auth-right register-page"},[_c('div',{staticClass:"language-dropdown"},[_c('ul',[_c('Language')],1)]),_c('div',{staticClass:"auth-detail"},[_c('h3',[_vm._v(_vm._s(_vm.$t("Referral"))+" : "+_vm._s(_vm.companyName))]),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 mb-1"},[_c('validation-provider',{attrs:{"name":"Company Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"type":"text","label":_vm.$t('Company Name'),"tooltipMessage":_vm.$t('Company Name'),"required":true},model:{value:(_vm.form.companyName),callback:function ($$v) {_vm.$set(_vm.form, "companyName", $$v)},expression:"form.companyName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-md-6 mb-1"},[_c('validation-provider',{attrs:{"name":"Address Line 1","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"type":"text","label":_vm.$t('Address Line 1'),"required":true},model:{value:(_vm.form.addressLine1),callback:function ($$v) {_vm.$set(_vm.form, "addressLine1", $$v)},expression:"form.addressLine1"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-md-6 mb-1"},[_c('TextInput',{attrs:{"type":"text","label":_vm.$t('Address Line 2')},model:{value:(_vm.form.addressLine2),callback:function ($$v) {_vm.$set(_vm.form, "addressLine2", $$v)},expression:"form.addressLine2"}})],1),_c('div',{staticClass:"col-md-6 mb-1"},[_c('validation-provider',{attrs:{"name":"City","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"type":"text","label":_vm.$t('City'),"required":true},model:{value:(_vm.form.city),callback:function ($$v) {_vm.$set(_vm.form, "city", $$v)},expression:"form.city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-md-6 mb-1"},[_c('validation-provider',{attrs:{"name":"ZIP","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"type":"text","label":_vm.$t('ZIP'),"required":true},model:{value:(_vm.form.zipCode),callback:function ($$v) {_vm.$set(_vm.form, "zipCode", $$v)},expression:"form.zipCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-md-6 mb-1"},[_c('validation-provider',{attrs:{"name":"Country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"type":"text","label":_vm.$t('Country'),"required":true},model:{value:(_vm.form.country),callback:function ($$v) {_vm.$set(_vm.form, "country", $$v)},expression:"form.country"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-md-6 mb-1"},[_c('TextInput',{attrs:{"type":"text","label":_vm.$t('VAT ID')},model:{value:(_vm.form.vatId),callback:function ($$v) {_vm.$set(_vm.form, "vatId", $$v)},expression:"form.vatId"}})],1),_c('div',{staticClass:"col-md-6 mb-1"},[_c('TextInput',{attrs:{"type":"text","label":_vm.$t('Phone')},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})],1),_c('div',{staticClass:"col-md-6 mb-2"},[_c('label',{staticClass:"input-label form-label",attrs:{"for":"cars"}},[_vm._v(_vm._s(_vm.$t("Status")))]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.status),expression:"form.status"}],staticClass:"input-form form-control",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "status", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"active"}},[_vm._v(" "+_vm._s(_vm.$t("Active"))+" ")]),_c('option',{attrs:{"value":"inactive"}},[_vm._v(" "+_vm._s(_vm.$t("Inactive"))+" ")])])]),_c('div',{staticClass:"col-md-6 mb-2"}),_c('div',{staticClass:"col-md-6 mb-1"},[_c('b-form-group',{attrs:{"label-for":"login-email"}},[_c('label',{staticClass:"input-label form-label"},[_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("Email"))+" ")]),_c('validation-provider',{attrs:{"name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],staticClass:"form-control",attrs:{"id":"email","name":"title","type":"text"},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "email", $event.target.value)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-md-6 mb-1"},[_c('b-form-group',[_c('label',{attrs:{"for":"login-password"}},[_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("Password"))+" ")]),_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"login-password","state":errors.length > 0 ? false : null,"type":_vm.passwordFieldType,"name":"login-password","placeholder":"············"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}])})],1)],1)]),_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('b-button',{attrs:{"variant":"primary","block":""},on:{"click":_vm.createCustomer}},[_vm._v(" "+_vm._s(_vm.$t("Register"))+" ")])],1)])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }